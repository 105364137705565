import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://4ce569109a754324a747f1d85a3131fa@o515505.ingest.sentry.io/5744337",
  enabled: process.env.NODE_ENV === "production",
  release: process.env.NEXT_PUBLIC_NPM_VERSION,
  ignoreErrors: [
    "CANCELLED_NAVIGATION",
    "TypeError: Cancelled",
    "Failed to fetch",
    "NetworkError",
  ],
});
